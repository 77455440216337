<template>
  <div id="about-page">
    <!-- Start Page Title Area -->
    <div class="page-title-area page-title-area-bg bg-4" data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000">
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <h2>PeaceJam Special Jury Award</h2>
            <p>
              We partner with the Monte Carlo TV Festival in June to award "The
              PeaceJam Special Jury" Award.
            </p>
            <p>
              This competition rewards the best TV series or documentary that
              embodies the Billion Acts Campaign and/or the spirit of the Nobel
              Peace Prize. The competition is free and open to the public.
            </p>
            <p>
              Submissions are currently closed for 2020 due to the Covid-19
              cancellation of the festival. Stay tuned for more information.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- End Page Title Area -->

    <!-- Start Team Area -->
    <section class="team-section pt-100 pb-100" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
      <div class="container">
        <div class="d-flex justify-content-center flex-wrap mb-5">
          <router-link to="/official-rules" class="special-jury-buttons">
            <i class="flaticon-curve-arrow"></i>
            Official Rules
          </router-link>

          <a href="https://goo.gl/forms/L9lXYsFF4vrFtpqa2" class="special-jury-buttons" target="_blank" rel="noreferrer">
            Entry Form
            <i class="flaticon-curve-arrow-1"></i>
          </a>
        </div>

        <div class="row" ref="mainImg">
          <div
            class="col-lg-4 col-md-6"
            v-for="(image, i) in images"
            :key="i"
            @click="index = i"
          >
            <div class="team-item">
              <div class="image">
                <!-- <img :src="image.url" alt="image" /> -->
                <img :src="require('../assets/img/'+ image.url)"/>
                <div class="content">
                  <h3>{{ image.title }}</h3>
                  <span>{{ image.subtitle }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- End Team Area -->
    <vue-gallery-slideshow
      :images="popupImg"
      :index="index"
      @close="index = null"
    ></vue-gallery-slideshow>
  </div>
</template>

<script>
import VueGallerySlideshow from "vue-gallery-slideshow";

export default {
  name: "SpecialJury",
  components: {
    VueGallerySlideshow
  },
  data() {
    return {
      images: [
        { url: 'specialjury/maker-8.jpg', title:'2019 Winner, Sarah Anthony', subtitle:"The Price of Free" },
        { url: 'specialjury/maker-1.jpg', title:'2018 Winner, Deeyah Khan', subtitle:"White Right: Meeting Your Enemy" },
        { url: 'specialjury/maker-2.jpg', title:'2018 Special Jury Members', subtitle:"" },
        { url: 'specialjury/maker-5.jpg', title:'2018 Winner, Deeyah Khan', subtitle:"White Right: Meeting the Enemy" },
        { url: 'specialjury/maker-7.jpg', title:'2018 Winner, Max Simonischeck', subtitle:"The Divine Order" },
        { url: 'specialjury/maker-3.jpg', title:'2018 Special Jury Meeting', subtitle:"" },
        { url: 'specialjury/maker-0.jpg', title:'2017 Winner, Rai Radiotelevisione Italiana', subtitle:"Lontano Dagli Occhi (Out of Sight)" },
        { url: 'specialjury/maker-6.jpg', title:'2017 Special Jury Members', subtitle:"" },
        { url: 'specialjury/maker-4.jpg', title:'2017 Nymph Awards Ceremony', subtitle:"@ Monte-Carlo TV Festival " },
      ],
      popupImg : [],
      index: null,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    setTimeout(() => {
      var mainDiv= this.$refs.mainImg;
      var img = mainDiv.getElementsByTagName('img');
      img.forEach((value) => {
          this.popupImg.push(value.src);
      });
    }, 2000);
  }
};
</script>

<style scoped lang="scss">
.accordion:not(.is-open) > .accordion-body {
  display: none;
}

@media only screen and (max-width: 1199px) and (min-width: 992px) {
  .faq-accordion .accordion .accordion-title {
      padding: 20px 30px 20px 30px;
  }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .faq-accordion .accordion .accordion-title {
    padding: 20px 30px 20px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .faq-accordion .accordion .accordion-title {
    padding: 20px 30px 20px 30px;
  }
}

.accordion__text-block {
  display: block;
}

.page-btn a {
  color: inherit;
}

.special-jury-buttons {
  padding: 10px 20px;
  border: 2px solid #7c70f2;
  color: #7c70f0;
  font-weight: 700;
  border-radius: 50px;

  &:first-child {
    margin-right: 10px;
  }

  &:last-child {
    margin-left: 10px;
  }

  &:hover {
    transform: translateY(-5px);
    color: #fff;
    background-color: #7c70f2;
    box-shadow: 0 3px 12px 2px rgba(0, 0, 0, 0.23);
  }
}

.section-title p {
  max-width: unset;
  margin: 20px 0px;
}

.team-item .image img {
      height: 280px;
    object-fit: cover;
    object-position: top;
}

.page-title-area-bg {
  padding: 60px 0;
  text-align: left;
  background-image: url("../assets/img/special-jury-award-bg.jpg");
  background-size: cover;
  background-position: center;

  p, h2 {
    color: #fff;
  }

  p {
    font-size: 17px;
  }

  h2 {
    margin-bottom: 15px;
  }
}

  @media (max-width: 357px) {
    .special-jury-buttons {
      width: 100%;
      text-align: center;
      margin: 0 0 10px 0 !important;
    }
  }

</style>
